<template>
  <UsersLayout>
    <v-simple-table class="my-table" :dense="!isLarge" :height="getTableHeight">
    <template v-slot:default>
    <UsersHeader
    @filter="onFilter"
    />
    <tbody>
        <UserRow
          v-for="(user, index) in users"
          :key="user.uuid"
          :user="user"
          :index="index"
          @delete="deleteUser"
          @resetFilter="reset"
          @toggleRow="toggleRow"
          :class="{ 'active-row': activeRow == user.uuid }"
        />
        <tr>
          <td
            colspan="9"
            v-intersect.quiet="nextPageHandler"
            v-if="!loadMoreDisabled && !loading"
          >
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </td>
        </tr>
        <tr v-if="!users.length && !loading">
          <td colspan="9">
            <div class="text-center">
              <h3>{{ $t("table.noData") }}</h3>
            </div>
          </td>
        </tr>
    </tbody>
    </template>
  </v-simple-table>
    <ConfirmDeleteDialog
      v-if="canDelete"
      :visible="confirmDelete"
      :uuid="uuidToDelete"
      @close="confirmDelete = false"
      @delete="deleteUser"
    />
    <EditUserDialog
      v-if="editUser && canEdit"
      @close="editUserDialog = false; editUser = ''"
      :user-item="editUser"
      :visible="editUserDialog"
    />
  </UsersLayout>
</template>

<script>
import UsersLayout from './UsersLayout.vue';
import loader from '../../mixins/loader';
import notifications from '../../mixins/notifications';
import usersService from '../../services/request/users/usersService';
import ConfirmDeleteDialog from '../../components/dialog/ConfirmDeleteDialog.vue';
import EditUserDialog from '../../components/users/EditUserDialog.vue';
import usersMixin from './usersMixin';
import user from '../../mixins/user';
import UserRow from './UserRow.vue';
import paginationMixin from '../../mixins/paginationMixin';
import UsersHeader from './UsersHeader.vue';
import SizeUi from "../../mixins/SizeUi";
import _ from "lodash";
import EventBus from "@/events/EventBus";

export default {
  name: 'Admins',
  components: {
    ConfirmDeleteDialog,
    EditUserDialog,
    UsersLayout,
    UserRow,
    UsersHeader,
  },
  mixins: [loader, notifications, usersMixin, user, paginationMixin,SizeUi],
  data: () => ({
    users: [],
    loadMoreDisabled: false,
    activeRow:'',
    isLoadingFilter:false,
    loading:false,
    isLoadingLine: false,
    windowHeight: document.documentElement.clientHeight,
    firstNumber: "",
    secondNumber: "",
    page: 1,
    perPageList: [5, 10, 15],
    resetFilter: false,

  }),
  mounted(){
    if (!this.$vuetify.breakpoint.smAndDown) {
      const elem = document.querySelector(".v-data-table__wrapper");
      elem.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    toggleRow(e){
    this.activeRow = e
    },
    async getUsersList(props = {},isLoadingLine,updated) {
      try {
        this.loading = true
        if(isLoadingLine){
          this.isLoadingFilter = true
        }else{
          // this.setLoading(true);
        }
        this.loading = true
        const params = {}
        params.offset = updated ? 0 : (this.page - 1) * this.perPage
        params.limit = updated ? this.largeLimit : this.perPage

        const newItems = await usersService.getAdminsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...props,
          order: 'DESC',
          orderby: 'time_created',
          ...params
        });
        if (newItems) {
          this.users.push(...newItems);
          this.users = this.getUniqueUsers(this.users);
          this.loadMoreDisabled = newItems.length < this.perPage;
        }else{
          this.loadMoreDisabled = true

        }
        if(isLoadingLine){
          this.isLoadingFilter = false
        }else{
          this.setLoading(false);
        }
        this.loading = false
      } catch (e) {
         this.loading = false
           if(isLoadingLine){
          this.isLoadingFilter = false
        }else{
          this.setLoading(false);
        }
        console.log(e)
      }
    },
    getWindowHeight: _.debounce(function () {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    handleScroll(e) {
      if (document.querySelector(".v-data-table__wrapper").scrollTop > 750) {
        EventBus.$emit("upBtn-modified", true);
      } else {
        EventBus.$emit("upBtn-modified", false);
      }
    },
    getPaginationParams() {
      return {
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage
      };
    },
    async prevPage() {
      if (this.page > 1) {
        this.page -= 1;
        await this.getUsersList();
      }
    },
    nextPageHandler: _.debounce(function (entries, observer, isIntersecting) {
      if (entries[0].isIntersecting) {
        console.info("[NS]: Is intersect ns code list");
        this.nextPage();
      }
    }, 100),
    async nextPage(updated) {
      this.page += 1;
      await this.getUsersList(updated);
    },
    reorderNumbers() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const tempResult = this.page * this.perPage;
      this.secondNumber = tempResult > this.total ? this.total : tempResult;
    },
    reset(e) {
    },
  },
  computed: {
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 500) {
        this.page = this.pageBeforeLimit - 1;
        this.$scrollTop();
        return 500;
      }
      return this.perPage * this.page;
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 145) / this.rowHeight);
    },
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);

      const formattedEvent = `${year}-${month}-${day}`;

      return formattedEvent;
    },
    nextPageDisabled() {
      return this.users.length < this.perPage;
    },
    getTableHeight() {
      return this.windowHeight - 154 + "px";
    }
  },
  watch: {
    loadMoreDisabled(e) {},
    page(e) {
      if (this.perPage * this.page >= 500 && this.perPage * this.page <= 500 + this.perPage) {
        this.pageBeforeLimit = e;
      }
    },
    async perPage() {
      await this.getUsersList();
    },
    filterProps: {
      deep: true,
      handler() {
        this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
      }
    }
  }
};
</script>

<style scoped>

</style>
